<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/about.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5 class="heading fw-semibold page-heading text-white title-dark">
            {{ data?.title ? data?.title : "Return Policy" }}
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ data?.title ? data?.title : "Return Policy" }}
          </li>
          <!-- <li class="breadcrumb-item  " aria-current="page">Products</li> -->
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Description -->
<section class="section">
  <div class="container mt-60">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row">
          <!--end col-->
          <div class="col-md-12" style="text-align: justify">
            <p class="text-muted">
              At Fiblota, we make it a goal to provide a hassle-free and fun
              experience with every dress rental. To ensure customer
              satisfaction, we have clearly outlined our return policy to ensure
              that you understand the process and requirements for returning
              rented items.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Period of Return</p>
            <p class="text-muted">
              All rented clothing should be returned on or before the date
              specified for return at the time of rental. Otherwise, returns
              made beyond this date shall incur additional charges as indicated
              in your rental agreement. Prompt return helps us manage our stock
              efficiently while others have an opportunity to use those items.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Condition of Return</p>
            <p class="text-muted" style="margin-bottom: 0">
              We kindly request that all items be returned in the same
              conditions as they were received:
            </p>
            <ul class="text-muted" style="line-height: 1.6">
              <li>
                <b>Clean and Damage-Free: </b>
                Dresses must not have stains, tears, or excessive wear. Normal
                wear is acceptable, but any damage beyond that may result in
                repair fees or a full replacement charge.
              </li>
              <li>
                <b>Original Packaging: </b>
                If applicable, kindly return the dress in original packaging
                that has been provided to protect it during transit.
              </li>
            </ul>

            <p style="margin-bottom: 0; font-size: 20px">Return Procedure</p>
            <p class="text-muted" style="margin-bottom: 0;">Returning your dress is easy:</p>
            <ul class="text-muted" style="line-height: 1.6">
              <li>
                <b>Package the Dress: </b>
                Place the dress in the assigned packaging.
              </li>
              <li>
                <b>Mark and Ship: </b>
                Attach the pre-addressed return label (if provided with the
                package) and return the item using your preferred shipping
                method. Ensure the item is returned by the specified date.
              </li>
              <li>
                <b>Confirmation: </b>
                Once we process and examine your return, a confirmation e-mail
                will be sent to you.
              </li>
            </ul>
            <p class="text-muted" style="margin-bottom: 0;">
              Additional Fee for Item Not Returned on Time or Is Damaged
            </p>
            <ul class="text-muted" style="line-height: 1.6">
              <li>
                <b>Late Fees: </b>
                For late returns, a daily fee will be charged. The late fee will
                continue to accrue until the item is returned or the cost of the
                dress is fully covered.
              </li>
              <li>
                <b>Damage Fees: </b>
                If significant damage occurs or if the item does not get
                returned, we can charge a repair fee or full replacement cost
                for loss.
              </li>
            </ul>

            <p style="margin-bottom: 0; font-size: 20px">Refunds and Credits</p>
            <p class="text-muted">
              Fiblota does not offer cash refunds on rentals. Should there be a
              flaw in the rented costume or fault on our part, we may be able to
              give you rental credits you can use next time. Reach out to us
              within 24 hours after receipt of an item for the concern or issue
              to discuss.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Contact Us</p>
            <p class="text-muted">
              Any questions or help with regards to this return process, just
              let our customer service team know. We're here for making your
              Fiblota experience smooth and enjoyable!
            </p>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Description -->

<app-footer-five />
