import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

interface Social {
  icon: string;
  link: string;
}
interface LinkOne {
  link: string;
  title: string;
}
interface LinkTwo {
  link: string;
  title: string;
}

interface linkThree {
  link: string;
  title: string;
}

@Component({
  selector: 'app-footer-five',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './footer-five.component.html',
  styleUrl: './footer-five.component.scss',
})
export class FooterFiveComponent {
  @Input() top: any;

  social: Social[] = [
    {
      icon: 'uil uil-twitter align-middle',
      link: 'https://x.com/Fiiblota',
    },
    {
      icon: 'ri-pinterest-fill align-middle',
      link: 'https://www.pinterest.com/fiblota/',
    },
    {
      icon: 'uil uil-youtube align-middle',
      link: 'https://www.youtube.com/@Fiblota',
    },
  ];

  linkOne: LinkOne[] = [
    {
      link: '/',
      title: 'Home',
    },
    {
      link: '/about',
      title: 'About us',
    },
    {
      link: '/shop',
      title: 'Shop',
    },
    {
      link: '/blogs',
      title: 'Blogs',
    },
    {
      link: '/contact',
      title: 'Contact Us',
    },
  ];

  linkTwo: LinkTwo[] = [
    {
      link: '/cookies-policy',
      title: 'Cookies Policy',
    },
    {
      link: '/privacy-policy',
      title: 'Privacy Policy',
    },
    {
      link: '/return-policy',
      title: 'Return Policy',
    },
    {
      link: '/terms-and-conditions',
      title: 'Terms & Condition',
    },
  ];

  linkThree: linkThree[] = [
    {
      link: '',
      title: '391 Elysian Fields Ct, Nashville, TN 37211, USA',
    },
    {
      link: '',
      title: 'Support desk',
    },
    {
      link: '/page-services',
      title: 'What we offer',
    },
    {
      link: '/page-aboutus',
      title: 'Company history',
    },
  ];

  year: any;

  ngOnInit(): void {
    this.year = new Date().getFullYear();
  }
}
