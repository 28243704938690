<!-- Footer Start -->
<footer class="footer bg-footer">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="footer-py-60">
          <div class="row">
            <div class="col-lg-4 col-12">
              <a [routerLink]="'/'" class="logo-footer">
                <img
                  [src]="'assets/images/logo-light.png'"
                  height="26"
                  alt=""
                />
              </a>
              <p class="mt-4">
                Fiblota is your premium dress rental company that provides
                unmatched style in a fraction of the cost.
              </p>
              <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                <li
                  *ngFor="let social of social"
                  class="list-inline-item"
                  style="margin: 0 2px !important"
                >
                  <a [href]="social.link" target="_blank" >
                    <i [class]="social.icon"></i>
                  </a>
                </li>
              </ul>
              <!--end icon-->
            </div>
            <!--end col-->

            <div class="col-lg-8 col-12">
              <div class="row">
                <div class="col-md-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <h6 class="footer-head">Quick Links</h6>
                  <ul class="list-unstyled footer-list mt-4">
                    <li *ngFor="let item of linkOne">
                      <a [routerLink]="item.link" class="text-foot"
                        ><i class="uil uil-angle-right-b me-1"></i>
                        {{ item.title }}</a
                      >
                    </li>
                  </ul>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <h6 class="footer-head">Other Links</h6>
                  <ul class="list-unstyled footer-list mt-4">
                    <li *ngFor="let item of linkTwo">
                      <a [routerLink]="item.link" class="text-foot"
                        ><i class="uil uil-angle-right-b me-1"></i>
                        {{ item.title }}</a
                      >
                    </li>
                  </ul>
                </div>
                <!--end col-->

                <div class="col-md-4 col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <h6 class="footer-head">Contact Us</h6>
                  <ul class="list-unstyled footer-list mt-4">
                    <li>
                      <i class="uil uil-location-point me-1"></i>
                      391 Elysian Fields Ct, Nashville, TN 37211, USA
                    </li>
                    <li>
                      <i class="uil uil-phone me-1"></i>
                      +1 6157272478
                    </li>
                    <li>
                      <i class="uil uil-envelope me-1"></i>
                      info&#64;fiblota.com
                    </li>
                  </ul>
                </div>
                <!--end col-->
              </div>
            </div>
          </div>
          <!--end row-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="footer-py-30 footer-bar bg-footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="text-center">
            <p class="mb-0 text-muted">
              Copyright ©
              {{ year }} <a href="/"> Fiblota</a>. All Right Reserved.
            </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </div>
</footer>
<!--end footer-->
<!-- End -->
