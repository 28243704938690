<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/blog01.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5 class="heading fw-semibold page-heading text-white title-dark">
            Sunset Silk Slip Dress
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Sunset Silk Slip Dress
          </li>
          <!-- <li class="breadcrumb-item  " aria-current="page">Products</li> -->
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-5">
        <div class="tiny-single-item">
          <div class="tiny-slide">
            <img
              [src]="'assets/images/shop/items/f4.png'"
              class="img-fluid rounded"
              alt=""
            />
          </div>
          <div class="tiny-slide">
            <img
              [src]="'assets/images/shop/items/b4.png'"
              class="img-fluid rounded"
              alt=""
            />
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-md-4">
          <h4 class="title">Sunset Silk Slip Dress</h4>
          <h5 class="text-muted">
            $56.00 <del class="text-danger ms-2">$70.00</del>
          </h5>

          <p class="text-muted">
            This dress oozes effortless charm with its luxurious silk fabric and
            sleek silhouette. If you are looking for a unique alternative to
            traditional dresses for your upcoming events, this slip dress can be
            your go-to choice.
            <br />
            <br />
            The fluid, soft drapes allow you to move gracefully, offering a
            feminine and sophisticated look that is both comfortable and chic.
          </p>

          <div class="mt-4 pt-2">
            <!-- <a [routerLink]="['/page-contact']" class="btn btn-primary">Contact Us</a> -->
            <a [routerLink]="'/checkout'" class="btn btn-soft-primary ms-2"
              >Rent the dress</a
            >
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <h4>Related Products</h4>

      <div class="row">
        <div class="col-12 mt-4 pt-2">
          <div class="tiny-four-item">
            <div *ngFor="let item of relatedProduct" class="tiny-slide">
              <div class="card shop-list border-0">
                <div
                  class="shop-image position-relative overflow-hidden rounded shadow"
                >
                  <a [routerLink]="[item.url]"
                    ><img
                      [src]="item.image1"
                      class="img-fluid product-image"
                      alt=""
                  /></a>
                  <a [routerLink]="[item.url]" class="overlay-work">
                    <img [src]="item.image2" class="img-fluid" alt="" />
                  </a>
                </div>
                <div class="card-body content pt-4 p-2">
                  <a
                    [routerLink]="[item.url]"
                    class="text-dark product-name h6"
                    >{{ item.name }}</a
                  >
                  <div class="d-flex justify-content-between mt-1">
                    <h6 class="text-muted small font-italic mb-0 mt-1">
                      {{ item.price
                      }}<del class="text-danger ms-2">{{ item.discount }}</del>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <!--end slide-->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<app-footer-five />
