import { Component } from '@angular/core';
import ShopData from '../../data/shop.json';
import { Title, Meta } from '@angular/platform-browser';
import * as feather from 'feather-icons';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../components/navbar/navbar/navbar.component';
import { FooterFiveComponent } from '../../components/footer/footer-five/footer-five.component';

interface ShopData {
  id: number;
  tag: string;
  image1: string;
  image2: string;
  name: string;
  discount: string;
  price: string;
  rate: string[];
  url: string;
}

interface TopProduct {
  image: string;
  name: string;
  descRate: string;
  rate: string;
}

@Component({
  selector: 'app-shop-grids',
  standalone: true,
  imports: [CommonModule, RouterLink, NavbarComponent, FooterFiveComponent],
  templateUrl: './shop-grids.component.html',
  styleUrl: './shop-grids.component.scss',
})
export class ShopGridsComponent {
  constructor(private titleService: Title, private metaService: Meta) {}
  ngOnInit(): void {
    this.setMetaData(
      'Our elegant rental collection | Fiblota',
      'Browse our wide variety of rental dresses and stand out in the crowd with your dream dress at an affordable price.'
    );
  }

  setMetaData(title: string, description: string): void {
    // Set the page title
    this.titleService.setTitle(title);

    // Update or add the meta description
    this.metaService.updateTag({ name: 'description', content: description });
  }

  product: ShopData[] = ShopData.slice(0, 8);

  ngAfterViewInit(): void {
    feather.replace();
  }

  categories: string[] = [
    'Men',
    'Women',
    'Electronics',
    'Jewellery',
    'Shoes',
    'Kid’s Wear',
    'Sports',
    'Toys',
  ];

  color: string[] = [
    'btn-primary',
    'btn-danger',
    'btn-success',
    'btn-info',
    'btn-secondary',
    'btn-warning',
  ];

  topProduct: TopProduct[] = [
    {
      image: 'assets/images/shop/items/f1.png',
      name: 'T-Shirt',
      descRate: '$18.00',
      rate: '$22.00',
    },
    {
      image: 'assets/images/shop/items/f3.png',
      name: 'Watch',
      descRate: '$18.00',
      rate: '$22.00',
    },
    {
      image: 'assets/images/shop/items/f6.png',
      name: 'Coffee Cup',
      descRate: '$18.00',
      rate: '$22.00',
    },
  ];
}
