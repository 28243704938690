[
  {
    "id": 1,
    "image": "assets/images/blog/01.jpg",
    "category": "Corporate",
    "title": "Perfect Summer Wedding Dress to Rent",
    "desc": "Summer weddings are enchanting, and sometimes overwhelming, combining warmth, romance, and sunshine.",
    "date": "13th Sept 2024",
    "url": "/perfect-summer-wedding-dress-to-rent"
  },
  {
    "id": 2,
    "image": "assets/images/blog/02.jpg",
    "category": "Corporate",
    "title": "How to Ensure Your Rental Dress Fits Perfectly",
    "desc": "If you are one of those people who want to stand out on special occasions, going for dress rental is probably...",
    "date": "13th Sept 2024",
    "url": "/how-to-ensure-your-rental-dress-fits-perfectly"
  }
]
