<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/about.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5 class="heading fw-semibold page-heading text-white title-dark">
            {{ data?.title ? data?.title : "Terms & Conditions" }}
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ data?.title ? data?.title : "Terms & Conditions" }}
          </li>
          <!-- <li class="breadcrumb-item  " aria-current="page">Products</li> -->
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Description -->
<section class="section">
  <div class="container mt-60">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row">
          <!--end col-->
          <div class="col-md-12" style="text-align: justify">
            <p style="margin-bottom: 0; font-size: 20px">Introduction</p>
            <p class="text-muted">
              These Terms and Conditions ("Terms") operate to govern the use of
              Fiblota's dress rental services ("Service"), offered exclusively
              within the United States. By accessing and using our services, you
              agree to be bound by these Terms. So please do read them before
              proceeding.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">
              Service Availability
            </p>
            <p class="text-muted">
              Fiblota rents out clothes only in the United States. We do not
              ship or have deliveries to countries outside the United States.
              All our rentals, shipping, and returns are addressed strictly to
              domestic addresses in the United States.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Rental Agreement</p>
            <p class="text-muted">
              By renting a dress from Fiblota, you enter into a rental agreement
              for the specified period. You agree to return the dress on or
              before the due date to avoid late fees. The dress must be returned
              in the same condition as received—clean and undamaged.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Payment and Billing</p>
            <p class="text-muted">
              For all rentals, only cash on delivery is accepted in Fiblota; we
              do not accept any form of credit card or online payment. Payments
              must always be made in full when the rented dresses are delivered.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">
              Data Protection and Privacy
            </p>
            <p class="text-muted">
              Fiblota respect your privacy. All applicable data protection laws,
              such as the Federal Trade Commission Act (FTC Act), are followed
              in the handling of personal data. We will only collect personal
              data like your name, address, and contact information for dealing
              with your rental orders and ensuring that the rented items are
              delivered properly to you. Your personal data will never be shared
              with third parties without your consent, except where required by
              law.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">
              Non-Disclosure of Data
            </p>
            <p class="text-muted">
              We respect your privacy and take every measure to protect your
              personal information. Fiblota will not share, sell, or distribute
              your personal information to any third parties. All customer data
              are used solely for processing the rentals and facilitating the
              delivery and return process.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Late Returns</p>
            <p class="text-muted">
              There are late charges for late returns. If you don't return the
              dress within the agreed time frame, you will incur a late fee.
              Failure to return items on time may result in the revocation of
              your ability to rent from Fiblota in the future.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">
              Limitation of Liability
            </p>
            <p class="text-muted">
              Fiblota does not assume any responsibility for indirect,
              incidental, or consequential damages that may result from the use
              of our services. In any case, our liability shall be measured
              strictly and solely by the amount of the rental fee paid for the
              dress.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Governing Law</p>
            <p class="text-muted">
              These Terms shall be construed and interpreted according to the
              Laws of the United States, and any controversy or disagreement
              arising between us shall be settled in the court of the
              appropriate jurisdiction within the United States.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">
              Modifications to Terms
            </p>
            <p class="text-muted">
              Fiblota reserves its right to amend these Terms at any time. All
              changes will be posted on our website, and you consent to the
              updated Terms by continuing to use our service after such change.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Contact Us</p>
            <p class="text-muted">
              For any questions concerning these Terms, please contact Fiblota
              customer service at info&#64;fiblota.com.
            </p>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Description -->

<app-footer-five />
