import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../components/navbar/navbar/navbar.component';
import { CountUpModule } from 'ngx-countup';
import { FooterFiveComponent } from '../../components/footer/footer-five/footer-five.component';
import { Title, Meta } from '@angular/platform-browser';

interface feature {
  icon: string;
  title: string;
  desc: string;
}

@Component({
  selector: 'app-page-aboutus',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NavbarComponent,
    CountUpModule,
    FooterFiveComponent,
  ],
  templateUrl: './page-aboutus.component.html',
  styleUrl: './page-aboutus.component.scss',
})
export class PageAboutusComponent {
  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.setMetaData(
      'Your trusted rental partner | Fiblota',
      'Our goal is to offer affordable and elegant dress rentals that elevate your appearance at every event.'
    );
  }

  setMetaData(title: string, description: string): void {
    // Set the page title
    this.titleService.setTitle(title);

    // Update or add the meta description
    this.metaService.updateTag({ name: 'description', content: description });
  }

  about: string[] = [
    'We offer a curated dress collection for our customers.',
    'Get access to high-quality and premium brands.',
    'Easy renting and fast delivery of dresses.',
  ];

  feature: feature[] = [
    {
      icon: 'uil uil-hunting',
      title: 'Our Vision',
      desc: `We aspire to become one of the exceptional rental dress providers of the United States, helping individuals to feel confident and look amazing.`,
    },
    {
      icon: 'uil uil-eye',
      title: 'Our Mission',
      desc: `With the goal of providing affordable and high-quality dress rental, we want to create an extensive collection that elevates everyone’s looks on every occasion.`,
    },
    {
      icon: 'uil uil-gold',
      title: 'Our Value',
      desc: `Customer-centricity, sustainability, and creativity are the core values of our business, fostering innovation and trust with every rental piece.`,
    },
  ];

  counterData = [
    {
      title: 'Investment Projects',
      value: 5458,
    },
    {
      title: 'Years of Experience',
      value: 15,
    },
    {
      title: 'Offices in the World',
      value: 54,
    },
    {
      title: 'Successful Cases',
      value: 214,
    },
  ];

  isOpen: any = false;

  togggleModal(e: any) {
    e.preventDefault();
    this.isOpen = !this.isOpen;
  }
}
