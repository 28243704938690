import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import BlogData from '../../data/blog/blog-grid.json';
import { FooterFiveComponent } from '../../components/footer/footer-five/footer-five.component';
import { NavbarComponent } from '../../components/navbar/navbar/navbar.component';
import { Title, Meta } from '@angular/platform-browser';

interface BlogData {
  id: number;
  image: string;
  category: string;
  title: string;
  desc: string;
  date: string;
  url: string;
}
@Component({
  selector: 'app-blog-grid',
  standalone: true,
  imports: [CommonModule, RouterLink, NavbarComponent, FooterFiveComponent],
  templateUrl: './blog-grid.component.html',
  styleUrl: './blog-grid.component.scss',
})
export class BlogGridComponent {
  blogData: BlogData[] = BlogData;

  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.setMetaData(
      'Explore our blogs | Fiblota',
      'Discover the latest rental trends, fashion tips, and helpful advice on our blog. Stay updated with current market trends and gain valuable insights.'
    );
  }

  setMetaData(title: string, description: string): void {
    // Set the page title
    this.titleService.setTitle(title);

    // Update or add the meta description
    this.metaService.updateTag({ name: 'description', content: description });
  }
}
