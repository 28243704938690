<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/blog01.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5 class="heading fw-semibold page-heading text-white title-dark">
            404 Error
          </h5>
          <p class="text-white-50 para-desc mx-auto mb-0">
            Our Latest Blog and News in Grid Layouts
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">404 Error</li>
          <!-- <li class="breadcrumb-item  " aria-current="page">Products</li> -->
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- ERROR PAGE -->
<section
  class="bg-home d-flex align-items-center"
  style="background: url('assets/images/cta05.png') center"
>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12 text-center">
        <img
          [src]="'assets/images/svg/404.png'"
          style="max-width: 500px"
          alt=""
        />
        <div class="text-uppercase mt-4 display-5 fw-semibold">
          Page Not Found
        </div>
        <div class="text-capitalize text-dark mb-4 error-page"></div>
        <p class="text-muted para-desc mx-auto">
          Oops! The page you're looking for doesn't exist or has been moved.
        </p>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-12 text-center">
        <a [routerLink]="'/'" class="btn btn-primary mt-4">Go To Home</a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- ERROR PAGE -->

<app-footer-five />
