<div class="row justify-content-center">
  <div *ngFor="let item of blogData" class="col-lg-4 col-md-6 mt-4 pt-2">
    <div class="card blog blog-primary shadow rounded overflow-hidden">
      <div class="image position-relative overflow-hidden">
        <img
          [src]="item.image"
          class="img-fluid"
          alt="Fiblota"
          loading="lazy"
        />

      <div class="card-body content">
        <a
          [routerLink]="item.url"
          class="h5 title text-dark d-block mb-0"
          >{{ item.title }}</a
        >
        <p class="text-muted mt-2 mb-2">{{ item.desc }}</p>
        <a [routerLink]="item.url" class="link text-dark"
          >Read More <i class="uil uil-arrow-right align-middle"></i
        ></a>
      </div>
    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->
