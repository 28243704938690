import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IndexFashionComponent } from './pages/index-fashion/index-fashion.component';
import { PageAboutusComponent } from './pages/about/page-aboutus.component';
import { ShopGridsComponent } from './pages/shop/shop-grids.component';
import { evening_breeze_wrap_dress } from './pages/evening-breeze-wrap-dress/shop-product-detail.component';
import { midnight_velvet_maxi } from './pages/midnight-velvet-maxi/shop-product-detail.component';
import { seaside_breeze_linen_shirt } from './pages/seaside_breeze_linen_shirt/shop-product-detail.component';
import { sunset_silk_slip_dress } from './pages/sunset-silk-slip-dress/shop-product-detail.component';
import { timeless_charcoal_suit } from './pages/timeless-charcoal-suit/shop-product-detail.component';
import { urban_gentleman_blazer } from './pages/urban-gentleman-blazer/shop-product-detail.component';
import { PageErrorComponent } from './pages/page-error/page-error.component';
import { BlogGridComponent } from './pages/blogs/blog-grid.component';
import { perfect_summer_wedding_dress_to_rent } from './pages/blog-detail-one/blog-detail-one.component';
import { how_to_ensure_your_rental_dress_fits_perfectly } from './pages/how-to-ensure-your-rental-dress-fits-perfectly/blog-detail-one.component';
import { cookies_policy } from './pages/cookies-policy/blog-detail-one.component';
import { privacy_policy } from './pages/privacy-policy/blog-detail-one.component';
import { return_policy } from './pages/return-policy/blog-detail-one.component';
import { terms_and_conditions } from './pages/terms-and-conditions/blog-detail-one.component';
import { PageContactComponent } from './pages/contact/page-contact.component';
import { ShopCheckoutComponent } from './pages/shop-checkout/shop-checkout.component';

export const routes: Routes = [
  { path: '', component: IndexFashionComponent },
  { path: 'about', component: PageAboutusComponent },
  { path: 'shop', component: ShopGridsComponent },
  { path: 'evening-breeze-wrap-dress', component: evening_breeze_wrap_dress },
  { path: 'midnight-velvet-maxi', component: midnight_velvet_maxi },
  { path: 'seaside-breeze-linen-shirt', component: seaside_breeze_linen_shirt },
  { path: 'sunset-silk-slip-dress', component: sunset_silk_slip_dress },
  { path: 'timeless-charcoal-suit', component: timeless_charcoal_suit },
  { path: 'urban-gentleman-blazer', component: urban_gentleman_blazer },
  { path: 'blogs', component: BlogGridComponent },
  { path: 'checkout', component: ShopCheckoutComponent },
  {
    path: 'perfect-summer-wedding-dress-to-rent',
    component: perfect_summer_wedding_dress_to_rent,
  },
  {
    path: 'how-to-ensure-your-rental-dress-fits-perfectly',
    component: how_to_ensure_your_rental_dress_fits_perfectly,
  },
  {
    path: 'cookies-policy',
    component: cookies_policy,
  },
  {
    path: 'privacy-policy',
    component: privacy_policy,
  },
  {
    path: 'return-policy',
    component: return_policy,
  },
  {
    path: 'terms-and-conditions',
    component: terms_and_conditions,
  },
  { path: 'contact', component: PageContactComponent },
  { path: 'page-error', component: PageErrorComponent },
  { path: '**', redirectTo: 'page-error', pathMatch: 'full' }, // Ensure this is at the end
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
