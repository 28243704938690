<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/about.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5 class="heading fw-semibold page-heading text-white title-dark">
            Shop
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Shop</li>
          <!-- <li class="breadcrumb-item  " aria-current="page">Products</li> -->
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="row g-4">
          <div *ngFor="let item of product" class="col-md-4 col-12">
            <div class="card shop-list border-0">
              <div
                class="shop-image position-relative overflow-hidden rounded shadow"
              >
                <a [routerLink]="[item.url]"
                  ><img
                    [src]="[item.image1]"
                    class="img-fluid product-image"
                    alt=""
                /></a>
                <a [routerLink]="[item.url]" class="overlay-work">
                  <img [src]="[item.image2]" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="card-body content pt-4 p-2">
                <a
                  [routerLink]="[item.url]"
                  class="text-dark product-name h6"
                  >{{ item.name }}</a
                >
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">
                    {{ item.price }}<del class="text-danger ms-2">{{item.discount}}</del>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<app-footer-five />
