<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/blog01.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5
            class="heading fw-semibold mb-0 page-heading text-white title-dark"
          >
            Rent a Dress
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item"><a [routerLink]="'/shop'">Shop</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            Rent a Dress
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section pb-0">
  <div class="container mt-60 mt-60">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title mb-5 pb-2 text-center">
          <!-- <h4 class="title mb-3">Rent a Dress</h4> -->
          <p class="text-muted para-desc mx-auto mb-0">
            Feel free to contact us if you have any questions about our services
            by filling out the form below. We are happy to help you.
          </p>
        </div>

        <div class="custom-form">
          <form id="myForm" (ngSubmit)="onSubmit(myForm)" #myForm="ngForm">
            <div class="row">
              <!-- Name Field -->
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">
                    Your Name <span class="text-danger">*</span>
                  </label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    class="form-control"
                    placeholder="Name :"
                    [(ngModel)]="formData.name"
                    #name="ngModel"
                    required
                  />
                  <div *ngIf="name.invalid && name.touched" class="text-danger">
                    Name is required.
                  </div>
                </div>
              </div>

              <!-- Email Field -->
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">
                    Your Email <span class="text-danger">*</span>
                  </label>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    class="form-control"
                    placeholder="Email :"
                    [(ngModel)]="formData.email"
                    #email="ngModel"
                    required
                  />
                  <div
                    *ngIf="email.invalid && email.touched"
                    class="text-danger"
                  >
                    <div *ngIf="email.errors?.['required']">
                      Email is required.
                    </div>
                    <div *ngIf="email.errors?.['email']">
                      Invalid email address.
                    </div>
                  </div>
                </div>
              </div>

              <!-- Subject Field -->
              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">
                    Subject <span class="text-danger">*</span>
                  </label>
                  <input
                    name="subject"
                    id="subject"
                    class="form-control"
                    placeholder="Subject :"
                    [(ngModel)]="formData.subject"
                    #subject="ngModel"
                    required
                  />
                  <div
                    *ngIf="subject.invalid && subject.touched"
                    class="text-danger"
                  >
                    Subject is required.
                  </div>
                </div>
              </div>

              <!-- Comments Field -->
              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">
                    Message <span class="text-danger">*</span>
                  </label>
                  <textarea
                    name="comments"
                    id="comments"
                    rows="4"
                    class="form-control"
                    placeholder="Message :"
                    [(ngModel)]="formData.comments"
                    #comments="ngModel"
                    required
                  ></textarea>
                  <div
                    *ngIf="comments.invalid && comments.touched"
                    class="text-danger"
                  >
                    Message is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="loading"
                  >
                    @if (!loading) {
                    <span *ngIf="!loading">Send Message</span>
                    }@else {
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Sending... }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <!--end custom-form-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <!--end container-->
  <div
    class="container mt-60 mt-60"
    style="margin-top: 100px; margin-bottom: 50px"
  >
    <div class="row">
      <div class="col-md-4">
        <div
          class="card border-0 text-center features feature-clean bg-transparent"
        >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-phone d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="footer-head">Phone</h5>
            <p class="text-muted">+1 6157272478</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div
          class="card border-0 text-center features feature-clean bg-transparent"
        >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="footer-head">Email</h5>
            <p class="text-muted">info&#64;fiblota.com</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div
          class="card border-0 text-center features feature-clean bg-transparent"
        >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="footer-head">Location</h5>
            <p class="text-muted">
              391 Elysian Fields Ct, Nashville, <br />TN 37211, USA
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->
<app-footer-five />
