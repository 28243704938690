<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/about.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5 class="heading fw-semibold page-heading text-white title-dark">
            {{ data?.title ? data?.title : "Privacy Policy" }}
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ data?.title ? data?.title : "Privacy Policy" }}
          </li>
          <!-- <li class="breadcrumb-item  " aria-current="page">Products</li> -->
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Description -->
<section class="section">
  <div class="container mt-60">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row">
          <!--end col-->
          <div class="col-md-12" style="text-align: justify">
            <p class="text-muted">
              At Fiblota, your privacy is important to us. We are committed to
              protecting your personal information. This Privacy Policy explains
              how we collect, use, and protect data during visits to or usage of
              our website and services. By using our website, you agree with the
              practices outlined here.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">
              Using our Website-Definition
            </p>
            <p class="text-muted">
              When we talk about "using our website," it covers accessing and
              browsing the online platform of Fiblota, looking at products,
              taking action for rentals, and engaging with our services. This
              use involves inputting personal data to rent a dress, making a
              customer support request, or subscribing to our updates.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Data We Collect</p>
            <ul class="text-muted" style="line-height: 1.6">
              <li>
                <b>Personal Data: </b>
                Personal details, including name, contact address, and shipping
                address when you rent from us.
              </li>
              <li>
                <b>Automatic Data: </b>
                We automatically collect information when you visit our site,
                including your server's IP address, the browser you use, and the
                pages you access. We collect it all to make improvements in our
                services, for better user experience, and for preventing
                misrepresentation of information.
              </li>
              <li>
                <b>Cookies: </b>
                Our website uses cookies to remember your preferences and
                enhance the site's functionality. You are allowed to alter your
                browser or decline cookies, but this would impact your user
                experience.
              </li>
            </ul>

            <p style="margin-bottom: 0; font-size: 20px">
              How Do We Utilize Your Information
            </p>
            <ul class="text-muted" style="line-height: 1.6">
              <li>
                <b>Handling Orders: </b>
                We use your data to process rental orders, ensure their
                delivery, and provide you with updates on their progress.
              </li>
              <li>
                <b>Contact information: </b>
                Contact information is used to solve requests and inquiries.
              </li>
              <li>
                <b>Improvement of Service: </b>
                Our automatically-collected data enables us to monitor your
                behavior as a user and improve our website to meet your
                requirement.
              </li>
            </ul>

            <p style="margin-bottom: 0; font-size: 20px">
              Data Protection / Security
            </p>
            <p class="text-muted">
              We respect your privacy and implement the industry-standard
              security measures to prevent unauthorized access, alteration, or
              disclosure of your personal data. All your personal information is
              kept safe, and access is granted only to authorized personnel.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">
              Compliance with the Federal Trade Commission Act (FTC Act)
            </p>
            <p class="text-muted">
              Fiblota complies with the directives laid down by the FTC Act,
              which requires transparency, fairness, and protection of
              consumers. We are fair and transparent regarding how your personal
              information is managed.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Children's Privacy</p>
            <p class="text-muted">
              Fiblota's website is not directed to persons under the age of 13,
              and we do not knowingly collect any personal information from such
              persons. We use your data to process rental orders, facilitate
              their delivery, and keep you informed with progress updates.
              Parents or guardians who believe that their child has provided us
              with his or her personal information should contact us at once.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Information Sharing</p>
            <p class="text-muted">
              We do not sell, share, or distribute your personal information to
              third parties for marketing purposes. Your data is used solely for
              internal operations, including fulfilling orders, customer
              support, and improving services. Disclosure to third parties may
              only occur if legally required, such as in response to a court
              order.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">
              Modifications to the Policy
            </p>
            <p class="text-muted">
              Fiblota shall be free to update this Privacy Policy from time to
              time in accordance with changes to its practices or any relevant
              laws. We use your data to manage rental orders, coordinate
              delivery, and provide progress updates.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Contact Us</p>
            <p class="text-muted">
              If you have any queries or issues about this Privacy Policy or how
              your data will be protected, please don't hesitate to contact us
              at info&#64;fiblota.com. We are here to help and ensure that your
              experience on Fiblota is safe and transparent.
            </p>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Description -->

<app-footer-five />
