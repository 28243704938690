import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../components/navbar/navbar/navbar.component';
import ShopData from '../../data/shop.json';
import { Title, Meta } from '@angular/platform-browser';
import * as feather from 'feather-icons';
import { tns } from 'tiny-slider';
import { BlogOneComponent } from '../../components/blog/blog-one/blog-one.component';
import { FooterFiveComponent } from '../../components/footer/footer-five/footer-five.component';

interface ShopData {
  id: number;
  tag: string;
  image1: string;
  image2: string;
  name: string;
  discount: string;
  price: string;
  rate: string[];
}

@Component({
  selector: 'app-index-fashion',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NavbarComponent,
    BlogOneComponent,
    FooterFiveComponent,
  ],
  templateUrl: './index-fashion.component.html',
  styleUrl: './index-fashion.component.scss',
})
export class IndexFashionComponent {
  product = ShopData.slice(0, 8);
  popularProduct = ShopData.slice(0, 8);

  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.setMetaData(
      'Explore our premium rental dresses | Fiblota',
      'Find the perfect rental dress with us and stay stylish at every event without breaking the bank. Enjoy a seamless, hassle-free rental process.'
    );
  }

  setMetaData(title: string, description: string): void {
    // Set the page title
    this.titleService.setTitle(title);

    // Update or add the meta description
    this.metaService.updateTag({ name: 'description', content: description });
  }

  ngAfterViewInit(): void {
    feather.replace();

    tns({
      container: '.tiny-four-item',
      controls: false,
      mouseDrag: true,
      loop: true,
      rewind: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      navPosition: 'bottom',
      speed: 400,
      gutter: 12,
      responsive: {
        992: {
          items: 4,
        },

        767: {
          items: 2,
        },

        320: {
          items: 1,
        },
      },
    });
  }

  offerData = [
    {
      image: 'assets/images/shop/items/bag2.png',
      title1: 'Book your dream',
      title2: 'dress now',
      bg: 'bg-soft-secondary',
    },
    {
      image: 'assets/images/shop/items/bag.png',
      title1: 'Transform your',
      title2: 'style today',
      bg: 'bg-soft-info',
    },
  ];
}
