import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { NavbarComponent } from '../../components/navbar/navbar/navbar.component';
import { FooterFiveComponent } from '../../components/footer/footer-five/footer-five.component';
import BlogData from '../../data/blog/blog-grid.json';
import { Title, Meta } from '@angular/platform-browser';

interface BlogData {
  id: number;
  image: string;
  category: string;
  title: string;
  desc: string;
  date: string;
}
@Component({
  selector: 'app-blog-detail-one',
  standalone: true,
  imports: [CommonModule, RouterLink, NavbarComponent, FooterFiveComponent],
  templateUrl: './blog-detail-one.component.html',
  styleUrl: './blog-detail-one.component.scss',
})
export class return_policy {
  blogData: BlogData[] = BlogData;

  id: any;
  data: any;

  constructor(
    private Router: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit(): void {
    this.setMetaData(
      'Read our return policy | Fiblota',
      'We offer a simple return process. Follow our guidelines for a hassle-free and smooth return experience.'
    );
    this.id = this.Router.snapshot.params['id'];
    this.data = this.blogData.find((item) => item.id === parseInt(this.id));
  }

  setMetaData(title: string, description: string): void {
    // Set the page title
    this.titleService.setTitle(title);

    // Update or add the meta description
    this.metaService.updateTag({ name: 'description', content: description });
  }

  social: string[] = [
    'uil uil-facebook-f',
    'uil uil-instagram',
    'uil uil-twitter',
    'uil uil-linkedin',
    'uil uil-dribbble',
  ];
}
