<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/about.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5 class="heading fw-semibold page-heading text-white title-dark">
            Our latest blogs
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            blogs
          </li>
          <!-- <li class="breadcrumb-item  " aria-current="page">Products</li> -->
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section">
  <div class="container">
    <div class="row g-4">
      <div *ngFor="let item of blogData" class="col-lg-4 col-md-6">
        <div class="card blog blog-primary shadow rounded overflow-hidden">
          <div class="image position-relative overflow-hidden">
            <img [src]="item.image" class="img-fluid" alt="" />

            <!-- <div class="blog-tag">
              <a [routerLink]="[]" class="badge text-bg-light">{{
                item.category
              }}</a>
            </div> -->
          </div>

          <div class="card-body content">
            <a
              [routerLink]="[item.url]"
              class="h5 title text-dark d-block mb-0"
              >{{ item.title }}</a
            >
            <p class="text-muted mt-2 mb-2">{{ item.desc }}</p>
            <a [routerLink]="[item.url]" class="link text-dark"
              >Read More <i class="uil uil-arrow-right align-middle"></i
            ></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<app-footer-five />
