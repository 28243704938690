<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/about.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5 class="heading fw-semibold page-heading text-white title-dark">
            {{ data?.title ? data?.title : "Cookies Policy" }}
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">
            {{ data?.title ? data?.title : "Cookies Policy" }}
          </li>
          <!-- <li class="breadcrumb-item  " aria-current="page">Products</li> -->
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Description -->
<section class="section">
  <div class="container mt-60">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="row">
          <!--end col-->
          <div class="col-md-12" style="text-align: justify">
            <p class="text-muted">
              We strive to enhance your browsing experience at Fiblota. This
              Cookies Policy outlines how we use cookies on our website and the
              choices available to you regarding their use.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">What Are Cookies?</p>
            <p class="text-muted">
              A cookie is a small text file placed on your device when you visit
              our website. The files contain data that help recognize your
              device and remember any information about your visit, such as
              preferences and actions.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">How We Use Cookies</p>
            <p class="text-muted" style="margin-bottom: 0">
              Fiblota uses cookies to enhance your online experience in the
              following ways:
            </p>
            <ul class="text-muted" style="line-height: 1.6;">
              <li>
                <b>Functionality Cookies: </b>
                These are necessary for the website to function properly. They
                guide you through our site, help you make a reservation, and
                access secure areas.
              </li>
              <li>
                <b>Performance Cookies: </b>
                We use these cookies to collect information about how visitors
                use our website. This data helps us identify issues, improve
                site functionality, and provide a better user experience.
              </li>
              <li>
                <b>Functional Cookies: </b>
                These cookies help us to keep in mind your preferences and show
                you customized content. For instance, they let us recall whether
                you had selected a specific type of dress or filter during your
                visit session.
              </li>
              <li>
                <b>Analytical Cookies: </b>
                We apply third-party tools in order to understand the behavior
                of the users visiting our website. They enable us to know how
                users interact with our website, which helps us to make informed
                improvements.
              </li>
            </ul>

            <p style="margin-bottom: 0; font-size: 20px">Third-Party Cookies</p>
            <p class="text-muted">
              We may work with a third-party service provider to administer
              these services and collect Activity Information and Site Usage
              Information. These third parties cannot access your personal
              information and have separate privacy policies governing their
              use.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Managing Cookies</p>
            <p class="text-muted">
              You control the use of cookies on our website, as many browsers
              let you adjust settings to block or delete them. While you can
              browse Fiblota without accepting cookies, certain functionalities
              may be limited or unavailable if cookies are disabled.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Policy Changes</p>
            <p class="text-muted">
              Fiblota may revise this Cookies Policy at its discretion at any
              time for any reason. We encourage you to check this policy
              periodically for the latest information regarding our use of
              cookies.
            </p>

            <p style="margin-bottom: 0; font-size: 20px">Contact Us</p>
            <p class="text-muted">
              If you have any questions about our use of cookies or how we
              handle your data, please reach out to our support team at
              info&#64;fiblota.com. We’re here to provide clarity and assistance
              regarding your privacy and browsing experience at Fiblota.
            </p>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Description -->

<app-footer-five />
