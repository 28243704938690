<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/about.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5
            class="heading fw-semibold mb-0 page-heading text-white title-dark"
          >
            Contact us
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">Contact Us</li>
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section pb-0">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div
          class="card border-0 text-center features feature-clean bg-transparent"
        >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-phone d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="footer-head">Phone</h5>
            <p class="text-muted">+1 6157272478</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div
          class="card border-0 text-center features feature-clean bg-transparent"
        >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="footer-head">Email</h5>
            <p class="text-muted">info&#64;fiblota.com</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div
          class="card border-0 text-center features feature-clean bg-transparent"
        >
          <div class="icons text-primary text-center mx-auto">
            <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
          </div>
          <div class="content mt-3">
            <h5 class="footer-head">Location</h5>
            <p class="text-muted">
              391 Elysian Fields Ct, Nashville, <br />TN 37211, USA
            </p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="section-title mb-5 pb-2 text-center">
          <h4 class="title mb-3">Reach out to us</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Feel free to contact us if you have any questions about our services
            by filling out the form below. We are happy to help you.
          </p>
        </div>

        <div class="custom-form">
          <form id="myForm" (ngSubmit)="onSubmit(myForm)" #myForm="ngForm">
            <div class="row">
              <!-- Name Field -->
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">
                    Your Name <span class="text-danger">*</span>
                  </label>
                  <input
                    name="name"
                    id="name"
                    type="text"
                    class="form-control"
                    placeholder="Name :"
                    [(ngModel)]="formData.name"
                    #name="ngModel"
                    required
                  />
                  <div *ngIf="name.invalid && name.touched" class="text-danger">
                    Name is required.
                  </div>
                </div>
              </div>

              <!-- Email Field -->
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">
                    Your Email <span class="text-danger">*</span>
                  </label>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    class="form-control"
                    placeholder="Email :"
                    [(ngModel)]="formData.email"
                    #email="ngModel"
                    required
                  />
                  <div
                    *ngIf="email.invalid && email.touched"
                    class="text-danger"
                  >
                    <div *ngIf="email.errors?.['required']">
                      Email is required.
                    </div>
                    <div *ngIf="email.errors?.['email']">
                      Invalid email address.
                    </div>
                  </div>
                </div>
              </div>

              <!-- Subject Field -->
              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">
                    Subject <span class="text-danger">*</span>
                  </label>
                  <input
                    name="subject"
                    id="subject"
                    class="form-control"
                    placeholder="Subject :"
                    [(ngModel)]="formData.subject"
                    #subject="ngModel"
                    required
                  />
                  <div
                    *ngIf="subject.invalid && subject.touched"
                    class="text-danger"
                  >
                    Subject is required.
                  </div>
                </div>
              </div>

              <!-- Comments Field -->
              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">
                    Message <span class="text-danger">*</span>
                  </label>
                  <textarea
                    name="comments"
                    id="comments"
                    rows="4"
                    class="form-control"
                    placeholder="Message :"
                    [(ngModel)]="formData.comments"
                    #comments="ngModel"
                    required
                  ></textarea>
                  <div
                    *ngIf="comments.invalid && comments.touched"
                    class="text-danger"
                  >
                    Message is required.
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="d-grid">
                  <button
                    type="submit"
                    class="btn btn-primary"
                    [disabled]="loading"
                  >
                    @if (!loading) {
                    <span *ngIf="!loading">Send Message</span>
                    }@else {
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Sending... }
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div
          *ngIf="this.messageStatus == false"
          style="
            color: green;
            font-size: 25px;
            font-weight: bold;
            font-family: 'Sour Gummy', sans-serif;
          "
        >
          Message Sent Successfully!
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container-fluid mt-100 mt-60">
    <div class="row">
      <div class="col-12 p-0">
        <div class="card map border-0">
          <div class="card-body p-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d829.6791855736537!2d-86.7299400369685!3d36.08765000093764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88646f9d9d886303%3A0x60b23c580bd81fa!2s391%20Elysian%20Fields%20Ct%2C%20Nashville%2C%20TN%2037211%2C%20USA!5e0!3m2!1sen!2sin!4v1732004487047!5m2!1sen!2sin"
              width="600"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<div
  class="modal"
  [ngClass]="isOpen ? 'd-block' : ''"
  style="background: #00000096"
>
  <div class="d-flex align-items-center justify-content-center h-100">
    <div class="position-relative">
      <div
        class="position-absolute top-0 translate-middle z-index-1"
        style="right: -30px"
      >
        <button
          type="button"
          class="btn-pills btn-sm btn-light btn-close opacity-10"
          style="width: 20px; height: 20px"
          (click)="togggleModal($event)"
        ></button>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>

<app-footer-five />
