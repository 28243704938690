[
  {
    "id": 1,
    "tag": "Featured",
    "image1": "assets/images/shop/items/f1.png",
    "image2": "assets/images/shop/items/b1.png",
    "name": "Evening Breeze Wrap Dress",
    "price": "$24.00",
    "discount": "$30.00",
    "rate": [
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star"
    ],
    "url": "/evening-breeze-wrap-dress"
  },
  {
    "id": 2,
    "tag": "",
    "image1": "assets/images/shop/items/f2.png",
    "image2": "assets/images/shop/items/b2.png",
    "name": "Midnight Velvet Maxi",
    "price": "$36.00",
    "discount": "$45.00",
    "rate": [
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star"
    ],
    "url": "/midnight-velvet-maxi"
  },
  {
    "id": 3,
    "tag": "Sale",
    "image1": "assets/images/shop/items/f3.png",
    "image2": "assets/images/shop/items/b3.png",
    "name": "Seaside Breeze Linen Shirt",
    "price": "$44.00",
    "discount": "$55.00",
    "rate": [
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star"
    ],
    "url": "/seaside-breeze-linen-shirt"
  },
  {
    "id": 4,
    "tag": "New",
    "image1": "assets/images/shop/items/f4.png",
    "image2": "assets/images/shop/items/b4.png",
    "name": "Sunset Silk Slip Dress",
    "price": "$56.00",
    "discount": "$70.00",
    "rate": [
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star"
    ],
    "url": "/sunset-silk-slip-dress"
  },
  {
    "id": 5,
    "tag": "",
    "image1": "assets/images/shop/items/f5.png",
    "image2": "assets/images/shop/items/b5.png",
    "name": "Timeless Charcoal Suit",
    "price": "$32.00",
    "discount": "$40.00",
    "rate": [
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star"
    ],
    "url": "/timeless-charcoal-suit"
  },
  {
    "id": 6,
    "tag": "",
    "image1": "assets/images/shop/items/f6.png",
    "image2": "assets/images/shop/items/b6.png",
    "name": "Urban Gentleman Blazer",
    "price": "$96.00",
    "discount": "$120.00",
    "rate": [
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star",
      "mdi mdi-star"
    ],
    "url": "/urban-gentleman-blazer"
  }
]
