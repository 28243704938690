<app-navbar />

<!-- Hero Start -->
<section class="home-slider position-relative">
  <div
    id="carouselExampleIndicators"
    class="carousel slide carousel-fade"
    data-bs-ride="carousel"
  >
    <div class="carousel-inner">
      <div class="carousel-item active" data-bs-interval="3000">
        <div
          class="bg-home pb-md-0 d-flex align-items-center"
          style="background: url('assets/images/shop/01.jpg') center"
        >
          <div class="bg-overlay bg-gradient-white-overlay"></div>
          <div class="container">
            <div class="row mt-5 mt-sm-0 align-items-center">
              <div class="col-lg-6 offset-lg-6 col-md-8 offset-md-4">
                <div class="title-heading mt-4 position-relative">
                  <h4
                    class="heading fw-bold mb-3 animated fadeInUpBig animation-delay-1"
                  >
                    Rent
                    <span class="position-relative text-type-element"
                      >dress</span
                    >
                    <br />
                    to impress
                  </h4>
                  <p
                    class="text-muted title-dark mx-auto para-desc animated fadeInUpBig animation-delay-2"
                  >
                    Elevate your style at every event without the commitment of
                    owning a dress with our wide variety of rental dresses.
                  </p>

                  <div class="mt-4 pt-2">
                    <a
                      [routerLink]="['/shop']"
                      class="btn btn-primary animated fadeInUpBig animation-delay-3"
                      >Explore Now</a
                    >
                  </div>

                  <div
                    class="position-absolute top-0 start-50 translate-middle"
                  >
                    <img
                      [src]="'assets/images/shop/rounded-shape.png'"
                      class="mover"
                      alt="Fiblota"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </div>
        <!--end slide-->
      </div>

      <div class="carousel-item" data-bs-interval="3000">
        <div
          class="bg-home pb-md-0 d-flex align-items-center"
          style="background: url('assets/images/shop/02.jpg') top"
        >
          <div class="bg-overlay bg-gradient-white-overlay"></div>
          <div class="container">
            <div class="row mt-5 mt-sm-0 align-items-center">
              <div class="col-lg-6 col-md-8">
                <div class="title-heading mt-4 position-relative">
                  <h4
                    class="heading fw-bold mb-3 animated fadeInUpBig animation-delay-1"
                  >
                    Rent your <br />
                    <span class="position-relative text-type-element"
                      >dream</span
                    >
                    look
                  </h4>
                  <p
                    class="text-muted title-dark mx-auto para-desc animated fadeInUpBig animation-delay-2"
                  >
                    Find the perfect dresses that put you on the spotlight in
                    any occasion. Look stunning effortlessly with our dress
                    rental services.
                  </p>

                  <div class="mt-4 pt-2">
                    <a
                      [routerLink]="['/shop']"
                      class="btn btn-primary animated fadeInUpBig animation-delay-3"
                      >Rent Now</a
                    >
                  </div>

                  <div class="position-absolute top-0 start-0 translate-middle">
                    <img
                      [src]="'assets/images/shop/rounded-shape.png'"
                      class="mover"
                      alt="Fiblota"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end container-->
        </div>
        <!--end slide-->
      </div>
    </div>
    <div class="carousel-indicators">
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="0"
        class="active"
        aria-current="true"
        aria-label="Slide 1"
      ></button>
      <button
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide-to="1"
        aria-label="Slide 2"
      ></button>
    </div>
  </div>
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start Shop Product -->
<section class="section pt-5">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h5 class="mb-0">Our popular products</h5>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div
        *ngFor="let item of product"
        class="col-lg-4 col-md-6 col-12 mt-6 pt-2"
      >
        <div class="card shop-list border-0">
          <div
            class="shop-image position-relative overflow-hidden rounded shadow"
          >
            <a [routerLink]="item.url"
              ><img [src]="item.image1" class="img-fluid product-image" alt=""
            /></a>
            <a [routerLink]="item.url" class="overlay-work">
              <img [src]="item.image2" class="img-fluid" alt="" />
            </a>
          </div>
          <div class="card-body content pt-4 p-2">
            <a
              [routerLink]="item.url"
              class="text-dark product-name h6"
              >{{ item.name }}</a
            >
            <div class="d-flex justify-content-between mt-1">
              <h6 class="text-muted small font-italic mb-0 mt-1">
                {{ item.price }}<del class="text-danger ms-2">{{item.discount}}</del>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row g-4">
      <div *ngFor="let item of offerData" class="col-lg-6 col-md-6">
        <div
          class="shop-advertise rounded p-4 py-md-5 px-lg-5"
          [ngClass]="item.bg"
        >
          <div class="border border-2 rounded p-4 py-md-5 px-lg-4 me-lg-5">
            <ul class="card list-unstyled mb-0">
              <li>
                <a [routerLink]="['/shop']" class="h4 text-dark"
                  >{{ item.title1 }} <br />
                  {{ item.title2 }}</a
                >
              </li>

              <li class="mt-4">
                <a [routerLink]="['/shop']" class="text-secondary border-bottom"
                  >Rent now</a
                >
              </li>
            </ul>
            <img [src]="item.image" class="img-fluid ad-image" alt="" />
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-12">
        <h5 class="mb-0">Customer’s choice</h5>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-12 mt-4 pt-2">
        <div class="tiny-four-item">
          <div *ngFor="let item of popularProduct" class="tiny-slide">
            <div class="card shop-list border-0">
              <div
                class="shop-image position-relative overflow-hidden rounded shadow"
              >
                <a [routerLink]="item.url"
                  ><img
                    [src]="item.image1"
                    class="img-fluid product-image"
                    alt=""
                /></a>
                <a
                  [routerLink]="item.url"
                  class="overlay-work"
                >
                  <img [src]="item.image2" class="img-fluid" alt="" />
                </a>
              </div>
              <div class="card-body content pt-4 p-2">
                <a
                  [routerLink]="item.url"
                  class="text-dark product-name h6"
                  >{{ item.name }}</a
                >
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">
                    {{ item.price }}<del class="text-danger ms-2">{{item.discount}}</del>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <!--end slide-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-3">Our latest blogs</h4>
          <p class="text-muted para-desc mx-auto mb-0">
            Explore our blog posts and learn the tips and tricks of dress
            rental. Stay updated with the latest happenings in fashion.
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-blog-one />
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End Shop Product -->
<app-footer-five />
