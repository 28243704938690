import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NavbarComponent } from '../../components/navbar/navbar/navbar.component';
import { FooterFiveComponent } from '../../components/footer/footer-five/footer-five.component';
import { FormsModule, NgForm } from '@angular/forms';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page-contact',
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    NavbarComponent,
    FooterFiveComponent,
    FormsModule,
  ],
  templateUrl: './page-contact.component.html',
  styleUrl: './page-contact.component.scss',
})
export class PageContactComponent {
  constructor(private titleService: Title, private metaService: Meta) {}
  ngOnInit(): void {
    this.setMetaData(
      'Connect with us | Fiblota',
      'Our team is available 24/7 to assist you with your dress rental queries. Do not hesitate to reach out to us.'
    );
  }

  setMetaData(title: string, description: string): void {
    // Set the page title
    this.titleService.setTitle(title);

    // Update or add the meta description
    this.metaService.updateTag({ name: 'description', content: description });
  }

  isOpen: any = false;
  // Form data object
  formData = {
    name: '',
    email: '',
    subject: '',
    comments: '',
  };

  loading: boolean = false;
  messageStatus: boolean = true;

  // Toggle Modal
  togggleModal(e: any) {
    e.preventDefault();
    this.isOpen = !this.isOpen;
  }

  //validation form
  validateForm() {
    const { name, email, subject, comments } = this.formData;

    if (!name || !email || !subject || !comments) {
      // alert('All fields are required!');
      return false;
    }

    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(email)) {
      // alert('Invalid email address!');
      return false;
    }
    return true;
  }

  // Form submission handler
  onSubmit(myForm: NgForm) {
    if (myForm.invalid) {
      // If the form is invalid, mark all controls as touched to trigger validation messages
      Object.keys(myForm.controls).forEach((field) => {
        const control = myForm.controls[field];
        control.markAsTouched();
      });

      return; // Stop the function here since the form is invalid
    }

    this.loading = true; // Start loading

    const data = { ...this.formData }; // Prepare form data

    // Send data to the server
    fetch('https://acomal.top/send-mail/44/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error('Failed to send the message');
        }
        return res.json();
      })
      .then((resData) => {
        // console.log('Success:', resData);
        // alert('Message sent successfully!');
        myForm.resetForm(); // Reset form
        this.formData = {
          name: '',
          email: '',
          subject: '',
          comments: '',
        }; // Clear form data
      })
      .catch((error) => {
        console.error('Error:', error);
      })
      .finally(() => {
        this.loading = false; // Stop loading
        this.messageStatus = false;
      });
  }
}
