<!-- Navbar STart -->
<header
  id="topnav"
  class="defaultscroll sticky"
  [ngClass]="{ 'nav-sticky': scroll, 'tagline-height': tagline }"
>
  <div class="container">
    <!-- Logo container-->
    <ng-container *ngIf="navLight; else elseTemplate">
      <a class="logo" [routerLink]="'/'">
        <span class="logo-light-mode">
          <img
            [src]="'assets/images/logo-dark.png'"
            class="l-dark"
            alt="Fiblota"
            loading="lazy"
          />
          <img
            [src]="'assets/images/logo-dark.png'"
            class="l-light"
            alt="Fiblota"
            loading="lazy"
          />
        </span>
        <img
          [src]="'assets/images/logo-dark.png'"
          class="logo-dark-mode"
          alt="Fiblota"
          loading="lazy"
        />
      </a>
    </ng-container>
    <ng-template #elseTemplate>
      <a class="logo" [routerLink]="'/'">
        <img
          [src]="'assets/images/logo-dark.png'"
          class="logo-light-mode"
          alt="Fiblota"
          loading="lazy"
        />
        <img
          [src]="'assets/images/logo-dark.png'"
          class="logo-dark-mode"
          alt="Fiblota"
          loading="lazy"
        />
      </a>
    </ng-template>

    <!-- End Logo container-->
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a
          class="navbar-toggle"
          [ngClass]="toggle ? 'open' : ''"
          id="isToggle"
          (click)="toggleMenu($event)"
        >
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <ul class="buy-button list-inline mb-0"></ul>

    <div id="navigation" [ngClass]="toggle ? 'd-block' : 'd-none'">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [ngClass]="{ 'nav-right': navRight }">
        <li
          class="has-submenu parent-parent-menu-item"
          [ngClass]="['/'].includes(menu) ? 'active' : ''"
        >
          <a [routerLink]="['/']">Home</a>
          <!-- <span class="menu-arrow"></span> -->
        </li>

        <li
          class="has-submenu parent-parent-menu-item"
          [ngClass]="['/about'].includes(menu) ? 'active' : ''"
        >
          <a [routerLink]="['/about']">About Us</a>
          <!-- <span class="menu-arrow"></span> -->
        </li>

        <li
          class="has-submenu parent-parent-menu-item"
          [ngClass]="
            [
              '/shop',
              '/shop-product-detail/1',
              '/shop-product-detail/2',
              '/shop-product-detail/3',
              '/shop-product-detail/4',
              '/shop-product-detail/5',
              '/shop-product-detail/6'
            ].includes(menu)
              ? 'active'
              : ''
          "
        >
          <a [routerLink]="['/shop']">Shop</a>
          <!-- <span class="menu-arrow"></span> -->
        </li>

        <li
          class="has-submenu parent-parent-menu-item"
          [ngClass]="
            [
              '/blogs',
              '/blog-detail-one/1',
              '/blog-detail-one/2',
              '/blog-detail-one/3'
            ].includes(menu)
              ? 'active'
              : ''
          "
        >
          <a [routerLink]="['/blogs']">Blogs</a>
          <!-- <span class="menu-arrow"></span> -->
        </li>

        <li [ngClass]="menu === '/contact' ? 'active' : ''">
          <a [routerLink]="'/contact'" class="sub-menu-item">Contact us</a>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
