<app-navbar [navLight]="true" />

<!-- Hero Start -->
<section
  class="bg-half-170 d-table w-100"
  style="background: url('assets/images/bg/about.jpg') center"
>
  <div class="bg-overlay bg-gradient-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-12">
        <div class="title-heading text-center">
          <h5
            class="heading fw-semibold mb-0 page-heading text-white title-dark"
          >
            About us
          </h5>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="position-middle-bottom">
      <nav aria-label="breadcrumb" class="d-block">
        <ul class="breadcrumb breadcrumb-muted mb-0 p-0">
          <li class="breadcrumb-item"><a [routerLink]="'/'">Home</a></li>
          <li class="breadcrumb-item active" aria-current="page">About Us</li>
        </ul>
      </nav>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Start -->
<section class="section" style="padding-top: 100px; padding-bottom: 0;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img
          [src]="'assets/images/about.jpg'"
          class="img-fluid rounded shadow"
          alt=""
        />
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-3">
            Our journey as an affordable dress rental company
          </h4>
          <p class="text-muted">
            At Fiblota, we aim to revolutionize the entire fashion industry of
            the United States with our dress rental services. We are the
            one-stop destination for affordable dress rental service for both
            men and women. We are dedicated to offering affordable fashion,
            customer satisfaction, and extensive collection.
          </p>
          <ul class="list-unstyled text-muted mb-0">
            <li *ngFor="let item of about" class="mb-0">
              <span class="text-dark h5 me-2"
                ><i class="uil uil-check-circle align-middle"></i></span
              >{{ item }}
            </li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center g-4">
      <div *ngFor="let item of feature" class="col-lg-4 col-md-6">
        <div
          class="card shadow p-4 rounded features features-classic feature-primary"
        >
          <i class="h1 mb-0 text-primary" [ngClass]="item.icon"></i>

          <div class="content my-3">
            <p class="title h5 text-dark">{{ item.title }}</p>

            <p class="text-muted mt-3">{{ item.desc }}</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container-fluid px-0 mt-100 mt-60">
    <div
      class="bg-half-260"
      style="background: url('assets/images/cta.jpg') top"
    >
      <div class="bg-overlay"></div>
    </div>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- End -->

<app-footer-five />

<div
  class="modal"
  [ngClass]="isOpen ? 'd-block' : ''"
  style="background: #00000096"
>
  <div class="d-flex align-items-center justify-content-center h-100">
    <div class="position-relative">
      <div
        class="position-absolute top-0 translate-middle z-index-1"
        style="right: -30px"
      >
        <button
          type="button"
          class="btn-pills btn-sm btn-light btn-close opacity-10"
          style="width: 20px; height: 20px"
          (click)="togggleModal($event)"
        ></button>
      </div>
    </div>
  </div>
</div>
